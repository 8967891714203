import React, {
  createContext,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { isDebug } from "./debug";

type InViewCallback = (count: number) => void;
type TrackFn = (el: RefObject<HTMLElement>, onInView: InViewCallback) => void;
export type TrackInViewContextType = {
  track: TrackFn;
  untrack: (el: RefObject<HTMLElement>) => void;
  debugging: boolean;
} | null;

const TrackInViewContext = createContext<TrackInViewContextType | undefined>(undefined);

const useTrackInViewContext = () => {
  const context = useContext(TrackInViewContext);
  if (!context) {
    throw Error("useTrackInViewContext can only be used inside an TrackInViewProvider");
  }
  return context;
};

type TrackInViewProviderProps = {
  children: ReactNode;
};

export const TrackInViewProvider = ({ children }: TrackInViewProviderProps) => {
  const ref = useRef<IntersectionObserver>();
  const [callbacks, setCallbacks] = useState(new Map());
  const [counters, setCounters] = useState(new Map());
  const debugging = isDebug();

  useEffect(() => {
    const io = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const callback = callbacks.get(entry.target);

        if (callback && entry.intersectionRatio > 0) {
          const counter = counters.get(entry.target);
          const count = (counter || 0) + 1;

          setCounters(counters.set(entry.target, count));

          callback(count);
        }
      });
    });

    ref.current = io;

    return () => {
      ref.current?.disconnect();
    };
  }, [callbacks, counters]);

  const track: TrackFn = (el, onInView) => {
    if (onInView && el.current) {
      setCallbacks(callbacks.set(el.current, onInView));
      ref.current?.observe(el.current);
    }
  };

  const untrack = (el: RefObject<Element>) => {
    if (el.current) {
      ref.current?.unobserve(el.current);
    }
  };

  const value = { track, untrack, debugging };

  return <TrackInViewContext.Provider value={value}>{children}</TrackInViewContext.Provider>;
};

type DebuggingInfoProps = {
  children: ReactNode;
  event?: string;
  params?: any;
  onInView?: InViewCallback;
};

const DebuggingInfo = ({ children, event, params, onInView }: DebuggingInfoProps) => {
  return (
    <div
      style={{
        border: "1px solid red",
        background: "black",
      }}
    >
      {event}
      {params}
      {onInView?.toString()}
      {children}
    </div>
  );
};

type Props = {
  children: ReactNode;
  onInView?: InViewCallback;
};

export const TrackInView = ({ children, onInView }: Props) => {
  const { track, untrack, debugging } = useTrackInViewContext();
  const me = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (me && onInView) {
      track(me, onInView);
    }

    return () => {
      untrack(me);
    };
  }, [onInView, track, untrack]);

  if (!debugging) {
    return <div ref={me}>{children}</div>;
  }

  return (
    <div ref={me}>
      <DebuggingInfo event="">{children}</DebuggingInfo>
    </div>
  );
};
